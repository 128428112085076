import type { QueryKey } from "@tanstack/react-query";
import { queryOptions } from "@tanstack/react-query";
import type {
    AdminVirtualMachine,
    VmActionLogResponse,
    VmAdminStatistics,
    VmDesignatedBody,
    VmIftuneBody,
    VmMigrateBody,
    VmRescueStartBody,
    VmResizeDiskBody,
} from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient.ts";
import { getResponseData } from "../../modules/api/apiClient.ts";
import { atomAllLocationsQuery } from "../../modules/location/atomAllLocationsQuery.ts";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { atomFamilyMulti } from "../../utils/jotai/atomFamilyMulti.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { buildQueryKey } from "../../utils/query/buildQueryKey.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export interface Params {
    location: string;
    uuid?: string;
}

export function getQueryVmKey(params?: Params): QueryKey {
    return params == null ? ["user-resource/admin/vm"] : ["user-resource/admin/vm", params.uuid, params.location];
}

export function getQueryStatisticsKey(params?: Params): QueryKey {
    return params == null
        ? ["base-operator/admin/vm/statistics/chart"]
        : ["base-operator/admin/vm/statistics/chart", params.uuid, params.location];
}

export interface VmAdminStatisticsLoc extends VmAdminStatistics, Params {}

function statisticsFromData(data: VmAdminStatistics, location: string): VmAdminStatisticsLoc {
    return { ...data, location };
}

export interface ActionLogParams {
    location: string;
    guest_uuid?: string;
}

export function getLogQueryKey(params: ActionLogParams): QueryKey {
    return buildQueryKey("base-operator/admin/action_log", params.location, params.guest_uuid);
}

export function getSingleQuery(apiClient: ApiClient, params: ActionLogParams) {
    return queryOptions({
        queryKey: getLogQueryKey(params),
        queryFn: async ({ signal }): Promise<VmActionLogResponse> => {
            const { location, guest_uuid } = params;
            return getResponseData(
                await apiClient.GET("/{location}/base-operator/admin/action_log", {
                    signal,
                    params: { query: { guest_uuid }, path: { location } },
                }),
            );
        },
    });
}

export const vmActionLogQueryAtom = atomFamilyMulti((location: string, guest_uuid: string) =>
    atomFromStandardQueryOptions(getSingleQuery, { location, guest_uuid }),
);

export function getSingleVmQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryVmKey(params),
        queryFn: async ({ signal }): Promise<AdminVirtualMachine> => {
            const { location, uuid } = params;
            return getResponseData(
                await apiClient.GET("/{location}/user-resource/admin/vm", {
                    signal,
                    params: { query: { uuid: uuid }, path: { location: location } },
                }),
            );
        },
    });
}

export const vmQueryAtom = atomFamilyMulti((location: string, uuid: string) =>
    atomFromStandardQueryOptions(getSingleVmQuery, { location, uuid }),
);

export function getSingleStatisticsQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryStatisticsKey(params),
        queryFn: async ({ signal }): Promise<VmAdminStatisticsLoc> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/base-operator/admin/vm/statistics/chart", {
                    signal,
                    params: { path: { location: location } },
                }),
            );

            return statisticsFromData(response, location);
        },
    });
}

export const statisticsQueryAtom = atomFamilyMulti((location: string) =>
    atomFromStandardQueryOptions(getSingleStatisticsQuery, { location }),
);

export const adminAllStatisticsQueryAtom = atomAllLocationsQuery(statisticsQueryAtom, (results) => results);

//#region Mutations

export const startVmMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "start",
    requestFn: async ({ uuid, location }: { uuid: string; location: string }) =>
        await apiClient.POST("/{location}/user-resource/admin/vm/start", {
            ...jsonEncodedBody,
            body: { uuid },
            params: { path: { location } },
        }),
}));

export const stopVmMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "stop",
    requestFn: async ({ uuid, location, force }: { uuid: string; location: string; force: boolean }) =>
        await apiClient.POST("/{location}/user-resource/admin/vm/stop", {
            ...jsonEncodedBody,
            body: { uuid, force },
            params: { path: { location } },
        }),
}));

export const deleteVmMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "delete",
    requestFn: async ({ uuid, location }: { uuid: string; location: string }) =>
        await apiClient.DELETE("/{location}/user-resource/admin/vm", {
            ...jsonEncodedBody,
            body: { uuid },
            params: { path: { location } },
        }),
}));

export const migrateVmMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "migrate",
    requestFn: async ({ body, location }: { body: VmMigrateBody; location: string }) =>
        await apiClient.POST("/{location}/base-operator/migrate", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
}));

export const updateNicIftuneMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "update",
    requestFn: async ({ body, location }: { body: VmIftuneBody; location: string }) =>
        await apiClient.PUT("/{location}/base-operator/admin/vm/iftune", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
}));

export const designatedVmMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "migrate",
    requestFn: async ({ body, location }: { body: VmDesignatedBody; location: string }) =>
        await apiClient.PATCH("/{location}/user-resource/admin/vm/designated_pool", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
}));

export const resizeDiskMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "update",
    requestFn: async ({ body, location }: { body: VmResizeDiskBody; location: string }) =>
        await apiClient.PATCH("/{location}/base-operator/admin/vm/storage", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
}));

export const rescueModeMutation = adminMutation((apiClient) => ({
    entity: "virtual machine",
    operation: "update",
    requestFn: async ({ body, location }: { body: VmRescueStartBody; location: string }) =>
        await apiClient.POST("/{location}/user-resource/admin/vm/rescue_start", {
            ...jsonEncodedBody,
            body,
            params: { path: { location } },
        }),
}));
//#endregion Mutations
