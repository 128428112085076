import type { QueryKey } from "@tanstack/react-query";

/**
 * Builds a TanStack query key.
 *
 * Strips any trailing `undefined` values from the arguments. Useful for invalidation, so you can invalidate entire
 * query sets (with arbitrary parameters) by setting the corresponding parameter to `undefined`.
 */
export function buildQueryKey(base: string, ...args: unknown[]): QueryKey {
    let trailingCount = 0;
    for (let i = args.length - 1; i >= 0; i--) {
        if (args[i] === undefined) {
            trailingCount++;
        } else {
            break;
        }
    }
    return [base, ...args.slice(0, args.length - trailingCount)];
}
